import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import styled from "@emotion/styled"

Modal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    padding: 0,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    margin: "auto",
    padding: 0,
    border: "none",
    borderRadius: 0,
    width: "100%",
    height: "100%",
  },
}

const ZoomImage = styled.img`
  width: 100%;
  max-width: 1500px;
  margin: 0px;
`

const ZoomImageBox = ({ open, imgSrc, closeHandle }) => {
  const [IsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    open ? openModal() : closeModal()
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <Modal
      isOpen={IsOpen}
      style={customStyles}
      contentLabel="Modal"
      onRequestClose={() => closeHandle(false)}
    >
      <div
        style={{
          margin: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          padding: "44px 0",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            padding: 5,
          }}
          onClick={() => closeHandle(false)}
        >
          <MdClose color="white" size={30} />
        </div>
        <ZoomImage className="lazyload" data-src={imgSrc}></ZoomImage>
      </div>
    </Modal>
  )
}

export default ZoomImageBox
